import React from "react";
import Layout from "../components/layout";

export default class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <h2>Page not found...</h2>
      </Layout>
    );
  }
}
